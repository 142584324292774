import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Alexandre Vassard</h1>
        <h2>Développeur TypeScript</h2>
      </header>
      <section className="App-section">
        <p>Projets en cours :</p>
        <p>
          <a href="https://mailbox.js.org" className="App-link">
            MailboxJS
          </a>
          <br />
          <a
            href="https://gitlab.com/alexandrevsd/lgtv-webos-api"
            className="App-link"
          >
            LG WebOS API Wrapper
          </a>
        </p>
      </section>
      <footer className="App-footer">
        <p>
          <a href="mailto:vassard.alexandre@outlook.fr" className="App-link">
            Contact
          </a>{" "}
          -{" "}
          <a href="https://github.com/Vsdparibas/" className="App-link">
            Github
          </a>
        </p>
      </footer>
    </div>
  );
}

export default App;
